.aboutHeader {
    grid-area: img;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-base);
    border: 5px solid #eee;
    border-radius: 10px;
    padding: 1em;
    gap: 0.25em;
    margin: 0 auto;
}

.aboutHeader h2 {
    margin-bottom: 0.1em;
}

.aboutImage {
    grid-area: header;
}

.aboutLink:hover, 
.aboutLink:focus {
    cursor: pointer;
    color: #1589FF;
}

nav {
    grid-area: nav
}

footer {
    grid-area: footer;
}


@media only screen and (max-width: 950px) {
    .aboutImage {
        display: none;
    }

    .aboutLink {
        color: #1589FF;
    }
    
    .aboutHeader {
        font-size: var(--font-size-sm);
    }
}


