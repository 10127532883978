@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico:wght@300;400;500;600;800&family=VT323&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Typography  */

html {
--font-size-sm: clamp(0.8rem, calc(0.17vw + 0.76rem), 0.89rem);
--font-size-base: clamp(1rem, calc(0.34vw + 0.91rem), 1.19rem);
--font-size-md: clamp(1.25rem, calc(0.61vw + 1.1rem), 1.58rem);
--font-size-lg: clamp(1.56rem, calc(1vw + 1.31rem), 2.11rem);
--font-size-xl: clamp(1.95rem, calc(1.56vw + 1.56rem), 2.81rem);
--font-size-xxl: clamp(2.44rem, calc(2.38vw + 1.85rem), 3.75rem);
--font-size-xxxl: clamp(3.05rem, calc(3.54vw + 2.17rem), 5rem);
}

/* Global styles */

body {
  color:#eee;
  background-color:#181818;;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Container  */

.container {
  max-width: 80%;
  margin: 0 auto;
  height: 100vh;
  display: grid;
  grid-template-areas: 
      "nav nav "
      "img header"
      "footer footer ";
  grid-template-columns: repeat(2fr);
  grid-template-rows: 10% 80% 10%;
  justify-items: center;
  align-items: center;
}


/* Nav stylings */

.mobileNavigation {
  display: none;
}

.Navbar {
  grid-area: nav;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.logo {
  font-size: clamp(1.95rem, calc(1.56vw + 1.56rem), 2.81rem);
  font-size: var(--font-size-xl);
  color:#1589FF;
  font-family: 'Pacifico', sans-serif;
  text-decoration: none;
  flex-basis: 30%;
}

nav.desktopNavigation ul {
  text-transform: uppercase;
  list-style: none;
  display: flex;
  grid-gap: 3em;
  gap: 3em;
}

nav.desktopNavigation ul li {
  font-size: clamp(1.56rem, calc(1vw + 1.31rem), 2.11rem);
  font-size: var(--font-size-lg);
  font-weight: 800;
}

nav.desktopNavigation ul li:hover,
nav.desktopNavigation ul li:focus {
  color: #1589FF;
}

/* Footer stylings */

footer {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: clamp(0.8rem, calc(0.17vw + 0.76rem), 0.89rem);
  font-size: var(--font-size-sm);
}

footer p {
  margin-left: auto;
}

.socials {
  margin-left: auto;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  cursor: pointer;
  font-size: clamp(1.25rem, calc(0.61vw + 1.1rem), 1.58rem);
  font-size: var(--font-size-md);
  color: #eee;
  text-decoration: none;
}
.socials a:hover,
.socials a:focus {
  color: #1589FF;
}

@media only screen and (max-width: 600px) {
 
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }



  .Navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .Navbar a:hover,
  .Navbar a:focus {
    color: #1589FF;
  }

  .desktopNavigation {
    display: none;
  }

  .mobileNavigation {
    display: flex;
    align-items: center;
  }

  .hamburger {
    cursor: pointer;
  }

  nav ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15%;
    right: 0;
    width: 100%;
    background-color: black;
    border-top: 2px solid #eee;
    opacity: 0.8;
  }

  nav ul li {
    padding-left: 15%;
    list-style: none;
    font-size: clamp(1rem, calc(0.34vw + 0.91rem), 1.19rem);
    font-size: var(--font-size-base);
    font-weight: 800;
  }


  footer {
    grid-area: footer;
    flex-direction: column;
  }

  footer p {
    margin-left: 0;
    order: 1;
  }
  
  .socials {
    margin-left: 0;
  }

}




.Home_homeImage__295-y {
    grid-area: img;
    border-radius: 50%;
    border: 10px solid white;
    max-width: 80%;
}

.Home_homeHeader__29RCD {
    grid-area: header;
    color: #CCCCCC;
    font-size: var(--font-size-lg);
}

nav {
    grid-area: nav
}

footer {
    grid-area: footer;
}

@media only screen and (max-width: 600px) {
    .Home_homeHeader__29RCD {
        font-size: var(--font-size-sm);
    }
}
.About_aboutHeader__3yc2T {
    grid-area: img;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-base);
    border: 5px solid #eee;
    border-radius: 10px;
    padding: 1em;
    grid-gap: 0.25em;
    gap: 0.25em;
    margin: 0 auto;
}

.About_aboutHeader__3yc2T h2 {
    margin-bottom: 0.1em;
}

.About_aboutImage__lw874 {
    grid-area: header;
}

.About_aboutLink__2YxZM:hover, 
.About_aboutLink__2YxZM:focus {
    cursor: pointer;
    color: #1589FF;
}

nav {
    grid-area: nav
}

footer {
    grid-area: footer;
}


@media only screen and (max-width: 950px) {
    .About_aboutImage__lw874 {
        display: none;
    }

    .About_aboutLink__2YxZM {
        color: #1589FF;
    }
    
    .About_aboutHeader__3yc2T {
        font-size: var(--font-size-sm);
    }
}




  .Work_projectContainer__1egiS {
    max-width: 80%;
    grid-column-start: span 2;
    grid-row: 2/3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
  }
  
  .Work_projectButton__2vNID {
    cursor: pointer;
    border: none;
    align-self: center;   
    border-radius: 50%;
    padding: 0.75em;
    display: flex;
  }

  .Work_projectDownArrow__Buo6S,
  .Work_projectUpArrow__1ipGs {
    display: none;
  }

  nav {
    grid-area: nav
}

footer {
    grid-area: footer;
}

  @media only screen and (max-width: 600px) {
    .Work_projectContainer__1egiS {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .Work_projectDownArrow__Buo6S,
    .Work_projectUpArrow__1ipGs {
      display: inline-block;
    }
    .Work_projectLeftArrow__t8iR6,
    .Work_projectRightArrow__1aYYY {
      display: none;
    }
}





.Card_cardContainer__1_E0T {
    max-width: 80%;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: all 0.5s ease-in-out;
    border: 5px transparent solid;
}

.Card_cardContainer__1_E0T:hover,
.Card_cardContainer__1_E0T:focus {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    border: 5px solid #eee;
}

.Card_cardImage__KywrI {
    max-width: 100%;
    cursor: pointer;
}

.Card_cardDetails__3SBNU {
    padding: 0.5em;
}
.Card_cardDescription__2X0IG {
    font-size: var(--font-size-base);
    margin-top: 0.2em;
}

.Card_cardTitle__xxyrR {
    font-size: var(--font-size-lg);
}

@media only screen and (max-width: 600px) {
    .Card_cardContainer__1_E0T {
        max-width: 100%;
        border: 5px solid #eee;
        -webkit-filter: none;
                filter: none;
        transition: none; 
        border: none;
    } 

    .Card_cardContainer__1_E0T:hover,
    .Card_cardContainer__1_E0T:focus {
    border: none;
}
}
